@use '../../vars' as *;

// TODO: Check with @andres for default link styles
.base {
  display: inline-block;
  text-decoration: inherit;
  font-weight: inherit;
  @include elHelpers();
}
.base:hover {
  text-decoration: underline;
}
// color variants
.primary {
  @include linkStyles($color-base-300, $color-base-400, $color-base-400);
  @include svgHoverStyles($color-base-300, $color-base-400, $color-base-400);
}
.secondary {
  @include linkStyles($color-neutral-500, $color-base-400, $color-base-400);
  @include svgHoverStyles($color-neutral-500, $color-base-400, $color-base-400);
}
.tertiary { // dark background
  @include linkStyles($color-neutral-0, $color-base-200, $color-base-200);
  @include svgHoverStyles($color-neutral-0, $color-base-200, $color-base-200);
}
.danger {
  @include linkStyles($color-info-destructive, $red-400, $red-400);
  @include svgHoverStyles($color-info-destructive, $red-400, $red-400);
}

.unstyled {
  @include svgHoverStyles($color-base-300, $color-base-400, $color-base-400);
}
